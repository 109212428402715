<template>
	<b-card :title="`${$t('Program Name')}：Test`">
		
	</b-card>
</template>

<script>
import { BCard, BCardText, BRow, BCol,BButton, BFormInput } from 'bootstrap-vue'

export default {
	components: {
		BCard,
		BCardText,
		BRow, BCol,
		BButton,
		BFormInput
	},
	data () {
		return {
			volumnSetStatus: 0,
			brightSetStatus: 0,
			bright: 0,
			volumn: 0
		}
	},
	methods: {
		saveVolumn () {
			this.volumnSetStatus = 0
		},
		saveBright () {
			this.brightSetStatus = 0
		}
	}
}
</script>

<style>

</style>
